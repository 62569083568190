.cdk-drag-placeholder {
  opacity: 0;
}
.cdk-drop-list-dragging {
  background-color: #fffff7;
}
.board-container.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 100ms cubic-bezier(0, 0, 0.2, 1);
}
.cdk-drag-animating {
  transition: transform 100ms cubic-bezier(0, 0, 0.2, 1);
}
.cdk-drag-preview {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size:1.12em;
  padding: 6px;
  margin-top: 5px;
  background-color: #fff;
  border-left: 3px solid #27acce;
  border-top: 1px solid #27acce;
  border-right: 1px solid #27acce;
  border-bottom: 1px solid #27acce;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  .board-column-item-header {
  }
  .board-column-item-content {
    margin-top:10px;
  }
  .board-column-item-text {
    padding:4px 4px 4px 4px;
    overflow: hidden;
    text-overflow: ellipsis;

  }
  .board-column-item-footer {
  }
  .board-hover-item {
    visibility: hidden;
  }
}
.board {
  overflow-x:auto;
  overflow-y:hidden;
  .bord-header {
  }
  .board-container {
    .board-column {
      flex: 0 0 auto;
      padding-bottom: 10px;
      width:11%;
      min-height: 100%;
      background-color: #f5f5f5;
      .board-column-header {
        padding:5px 10px 5px 10px;
        font-weight:300;
        font-size:16px;
        letter-spacing: 0.08rem;
        border-bottom: 3px solid #cdcdcd;
        background-color:#fff;
      }
      .board-column-content {
        padding:5px 10px 5px 10px;
        height: 100%;
        min-height:80vh;
        .board-column-item {
          font-size:0.9em;
          padding:10px;
          margin-top: 10px;
          background-color:#fff;
          border-left: 3px solid #1f76b1;
          border-top: 1px solid #cdcdcd;
          border-right: 1px solid #cdcdcd;
          border-bottom: 1px solid #cdcdcd;
          .board-column-item-header {
          }
          .board-column-item-content {
            margin-top:10px;
          }
          .board-column-item-text {
            padding:4px 4px 2px 4px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            // min-width: 28vh;
          }
          .board-column-item-footer {
          }
          .board-hover-item {
            visibility: hidden;
          }
        }
        .board-column-item:hover .board-hover-item{
          visibility: visible;
        }
      }
    }
  }
  .board-footer {

  }
}