.menubar {
  border-radius:0;
  background-color:#fff;
  padding:0;
}

.menubar .menubar-logo {
  height:30px;
  width:30px;
  background-size: 30px 30px;
  background-image: url('~src/assets/image/swcg-logo.png');
  background-repeat: no-repeat;
}

.menubar .p-menubar-root-list {
   .p-menuitem > .p-menuitem-link .p-menuitem-text {
     font-size: 14px;
     font-weight:400;
     text-shadow: #ddd 1px 1px;
  }
  .p-menuitem-link .p-menuitem-text {
    font-size: 14px;
    font-weight:400;
    text-shadow: #ddd 1px 1px;
  }
  .p-menuitem-link .pi-home {
    padding-left: 8px;
  }
}


.menubar .p-menubar-end {
  .p-menuitem-link .pi-file-o {
    padding-left: 8px;
  }

  .p-menuitem-link .pi-user {
    padding-left: 18px;
  }

  .p-menuitem-link {
    overflow: visible;

   .p-submenu-icon{
    display: none !important;
  }
}

  .p-menuitem-link .pi-sign-out {
    padding-left: 18px;
  }

  .p-menuitem-link .pi-comments {
    padding-left: 14px;
  }

  .p-menuitem-link .pi-bell {
    padding-left: 35px;
  }

  .p-menuitem-link .pi-arrow-right {
    padding-left: 20px;
  }

  .p-menuitem-link .p-menuitem-text {
    font-size: 12px;
    font-weight:400;
    text-shadow: #ddd 1px 1px;
  }

  .p-menuitem-link, .p-menuitem-link span {
    color:#222 !important;
    display: block !important;
  }
}

