.p-card {
  padding: 1.1rem;
  .p-card-body {
  }
  .p-card-title {
    padding-bottom:.65rem;
    font-size:24px;
    letter-spacing: 0.050em;
    font-weight:500;
  }
  .p-card-title:first-letter {

  }
}

