@import "../node_modules/primeng/resources/themes/saga-blue/theme.css";
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/primeflex/primeflex.css";
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";
@import "../node_modules/quill/dist/quill.bubble.css";
@import "app/styles/_board";
@import "app/styles/_common";
@import "app/styles/auth";
@import "app/styles/_menubar";
@import "app/styles/_side-nav";
@import "app/styles/_table";
@import "app/styles/_toolbar";
@import "app/styles/_progressbar";
@import "app/styles/_card";
@import "app/styles/_panel";