.login-form, .register-form {
  input {
    font-size:14px;
  }
  .p-field {
    font-size:14px;
  }
  .p-button {
    font-size:14px;
  }
}

.login-form {

}

.register-form {

}