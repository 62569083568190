body, html {
  margin:0;
  padding:0;
  overflow: hidden;
  color:#222;
  font-size:0.8em;
  background-color: #1f76b1;
}

.container-content {
  width:100%;
  overflow-y: auto;
  height:96vh;
}

.p-panel {
  -webkit-box-shadow: 0 4px 6px -6px #222;
  -moz-box-shadow: 0 4px 6px -6px #222;
  box-shadow: 0 4px 6px -6px #222;
  .p-panel-header {

  }
  .p-panel-header {
    border-color: #f5f5f5 !important;
  }
  .p-panel-content {
    border-color: #f5f5f5 !important;
  }
}

.true-icon {
  color: #2f9a34;
}
.false-icon {
  color: #af3030;
}

.ul-no-dots{
  list-style-type: none;
}

.resume-kebab-menu{
  top: unset !important;
  left: unset !important;
}

.full-width-input {
  width: 100%;
}
