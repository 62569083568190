.side-nav {
  padding:0;
  width:90px;
  height:100vh;
  border-radius:0;
}


.side-nav {
  .p-menuitem-active > p-tieredmenusub > .p-submenu-list {
    z-index:100;
  }
  .side-nav-menuitem {
    padding:0;
    text-align:center;
    width:100%;
    height:90px;
    cursor:pointer;
  }

  .side-nav-menuitem:last-child {
    border-top:1px solid rgba(0, 88, 146, 0.58);
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 90px;
    cursor:pointer;
  }
  .side-nav-menuitem:last-child .p-menuitem-link {
    border-bottom:0;
  }
  .side-nav-menuitem:last-child .p-menuitem-link:hover, .side-nav-menuitem:last-child .p-menuitem-link:focus{
    border-bottom:0;
  }

  .side-nav-menuitem .p-menuitem-link {
    border-bottom:1px solid rgba(0, 88, 146, 0.58);
    padding: 2.0rem 1rem;
    display: block;
    box-shadow:none;
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
  }

  .side-nav-menuitem .p-menuitem-link:hover, .side-nav-menuitem .p-menuitem-link:focus {
    background-color: #f5f5f5;
    border-bottom: 1px solid #ffd600;
    .pi {
      color: #10548e;
      font-size:20px;
      width:30px;
    }
    .p-menuitem-text {
      padding-top:6px;
      font-size: 11px;
      font-weight:400;
      text-shadow: #ddd 1px 1px;
      color:#222;
    }
  }

  .side-nav-menuitem .pi {
    color: #005eb6;
    font-size:20px;
    width:30px;
  }

  .side-nav-menuitem .pi-file-o {
    padding-left: 6px;
  }

  .side-nav-menuitem .pi-users {
    padding-left: 6px;
  }

  .side-nav-menuitem .pi-sitemap {
    padding-left: 6px;
  }

  .side-nav-menuitem .pi-th-large {
    padding-left: 6px;
  }

  .side-nav-menuitem .pi-cog {
    padding-left: 6px;
  }

  .side-nav-menuitem .p-menuitem-text {
    padding-top:6px;
    font-size: 11px;
    font-weight:400;
    text-shadow: #ddd 1px 1px;
    color:#222;
  }

  .side-nav-menuitem .p-submenu-icon {
    display:none !important;
  }

  .side-nav-menuitem .p-menuitem.p-menuitem > .p-menuitem-link   {
    border:0;
    padding-top:10px;
    width:120px;
    font-size:14px;
  }
}